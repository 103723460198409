/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { fb, db, timestamp, currentUser, functions } from "../../fbUtils.js";

export const state = {
	currentUser: currentUser(), //sessionStorage.getItem('authUser'),
	roles: [],
};

export const mutations = {
	SET_CURRENT_USER(state, newValue) {
		state.currentUser = newValue;
		saveState("auth.currentUser", newValue);
		//if (getters.loggedIn) {
		//	console.log(getters.loggedIn);
		//	console.log("STILL LOGGED IN! " + newValue);
		//}
	},
};

export const getters = {
	// Whether the user is currently logged in.
	loggedIn(state) {
		//console.log("LOGGED IN: " + JSON.stringify(state.currentUser));
		//return !!state.currentUser;
		return (
			state.currentUser !== null &&
			typeof state.currentUser === "object" &&
			Object.keys(state.currentUser).length > 0
		);
	},
};

export const actions = {
	// This is automatically run in `src/state/store.js` when the app
	// starts, along with any other actions named `init` in other modules.
	// eslint-disable-next-line no-unused-vars
	init({ state, dispatch }) {
		dispatch("validate");
	},
	linkLoginStatus({ commit, dispatch, getters }, { link } = {}) {
		//console.log(link);
		//console.log(fb().checkEmailLink(link));
		return fb().checkEmailLink(link);
	},
	// Logs in the current user.
	//NEED
	async logIn(
		{ commit, dispatch, getters },
		{ email, password, link, linkLogin = false } = {}
	) {
		if (getters.loggedIn) return dispatch("validate");
		let emailLower = (email || "").toLowerCase();
		var userResult = { user: null, userDoc: null, error: null, roles: null };
		var response = null;
		if (link && linkLogin === true) {
			if (fb().checkEmailLink(link)) {
				response = await fb().loginUserLink(emailLower, link);
			}
		} else {
			response = await fb().loginUser(emailLower, password);
		}

		if (response) {
			const user = response;
			if (user) {
				var uid = user.uid;
				if (user.emailVerified === true) {
					let userDoc = {};
					//check if this user is in users
					//JR - I do this because using serverTimestamp() we would have to get the user doc again to have a real created
					//BUT - this might cause timeszone issues - non utc etc.
					let now = timestamp().fromDate(new Date());
					const claims = (await fb().getClaims()) || {};
					//console.log(claims);
					//let userSnap = null;
					let docId = null;
					let id = null;
					//ref for new user if needed
					let usersRef = db().collection("users").doc();
					if (
						claims.id &&
						claims.type &&
						claims.type !== "health professional"
					) {
						// userSnap = await db().collection('users').where('email','==', emailLower).limit(1).get();
						// if (userSnap.docs.length > 0) {
						//     docId = userSnap.docs[0].id;
						//     //is this a current user or new
						//     userDoc = userSnap.docs[0].data();
						// }
						id = claims.id;
						const userDocData = await db().collection("users").doc(id).get();
						if (userDocData.exists) {
							docId = id;
							userDoc = userDocData.data();
						}
						//fill in facility id in claims if missing
						if (
							claims.type === "facility admin" ||
							claims.type === "facility timesheet approver"
						) {
							if (!claims.facilityId) {
								const params = {
									email: emailLower,
									uid: uid,
									id: id,
									type: claims.type,
								};
								const idResultUpdate =
									functions().httpsCallable("getUserIdByEmail");
								await idResultUpdate(params);
							}
						}
					} else {
						//calling by id - see if we have it
						if (claims.id) {
							id = claims.id;
							const userDocData = await db().collection("users").doc(id).get();
							if (userDocData.exists) {
								docId = id;
								userDoc = userDocData.data();
							}
						} else {
							//passing in type hp but if doc is already there then that is used - this type is an overload for a totally new user with no doc - this will
							//set their claims so they can create it
							const params = {
								email: emailLower,
								uid: uid,
								id: usersRef.id,
								type: "health professional",
							};
							var idResult = functions().httpsCallable("getUserByEmail");
							const result = await idResult(params);
							if (result.data) {
								userDoc = result.data || {};
								docId = userDoc.id;
								id = docId;
							}
						}

						// if (id) {
						//     console.log(id);
						//     const userDocData = await db().collection('users').doc(id).get()
						//     if (userDocData.exists) {
						//         console.log('UDOC!')
						//         docId = id;
						//         userDoc = userDocData.data();
						//     }
						// }
					}
					//let userSnap = await db().collection('users').where('email','==', emailLower).limit(1).get();
					//let userSnap = await db().collection('users').where('uid','==', uid).limit(1).get();
					if (Object.keys(userDoc).length > 0) {
						userDoc.uid = uid;
						userDoc.id = docId;
						if (userDoc.status) {
							//handle both that they were added in through admin
							if (userDoc.status === "new") {
								userDoc.created = now;
								userDoc.updated = now;
								userDoc.status = "active";
								let usersRef = db().collection("users").doc(docId);
								await usersRef.set(userDoc, { merge: true });
							} else {
								if (userDoc.status === "archived") {
									//archived - don't let them in unless admin sets them to active
									userResult.error = "archived";
									await fb().logout();
									return userResult;
								}
							}
						}
					} else {
						// CHANGE - JR - 10/9/23 - set up HP account for user if they login and it isn't there
						if (!id) {
							id = usersRef.id;
						} else {
							usersRef = db().collection("users").doc(id);
						}
						//console.log('NEW USER DOC');
						userDoc.uid = uid;
						userDoc.id = id;
						userDoc.created = now;
						userDoc.updated = now;
						userDoc.setupDate = now;
						userDoc.status = "active";
						userDoc.type = "health professional";
						userDoc.email = emailLower;
						await usersRef.set(userDoc, { merge: true });
					}

					//console.log("set current user");

					userResult.user = user;
					userResult.userDoc = userDoc;
					commit("SET_CURRENT_USER", user);

					//roles
					if (userResult.user) {
						var roles = await fb().getRoles();
						//console.log("set roles!");
						//console.log(roles);

						if (roles) {
							userResult.roles = roles;
							//build permissions from roles.
							//merge lists from all roles
						}
					}
				} else {
					userResult.error = "not-verified";
					await fb().logout();
				}
			}
		}
		// } catch (error) {
		//     console.log({ error });
		//     //return fb()._handleError(error);
		// }

		return userResult;
	},

	// Logs out the current user.
	logOut({ commit }) {
		console.log("logout");
		// eslint-disable-next-line no-unused-vars
		commit("SET_CURRENT_USER", null);
		return new Promise((resolve, reject) => {
			// eslint-disable-next-line no-unused-vars
			fb()
				.logout()
				.then((response) => {
					//this.$store.commit('setFirebaseUser', null);
					resolve(true);
				})
				.catch((error) => {
					reject(fb()._handleError(error));
				});
		});
	},

	// register the user
	async register({ commit, dispatch, getters }, { email, password } = {}) {
		if (getters.loggedIn) return dispatch("validate");

		try {
			let user = await fb().registerUser(email, password);
			if (user) {
				let sendResult = await user.sendEmailVerification();

				//if it doesn't exist reserve their user id in claims
				let usersRef = db().collection("users").doc();
				const params = {
					email: email,
					uid: user.uid,
					id: usersRef.id,
					type: "health professional",
				};
				var idResult = functions().httpsCallable("getUserIdByEmail");
				await idResult(params);

				//console.log(sendResult);
				commit("SET_CURRENT_USER", user);
				return user;
			} else {
				console.log("error - no user");
				return "There was an error with registration, please try again later or contact support.";
			}
		} catch (error) {
			if (error) {
				if (error.message) {
					return fb()._handleError(error);
				}
			}
			return (
				error ||
				"There was an error with registration, please try again later or contact support."
			);
		}
	},

	// register the user
	// eslint-disable-next-line no-unused-vars
	resetPassword({ commit, dispatch, getters }, { email } = {}) {
		if (getters.loggedIn) return dispatch("validate");

		return fb()
			.forgetPassword(email)
			.then((response) => {
				const message = response.data;
				return message;
			});
	},

	// Validates the current user's token and refreshes it
	// with new data from the API.
	// eslint-disable-next-line no-unused-vars
	validate({ commit, state }) {
		//console.log("validate");
		//console.log(JSON.stringify(state));
		if (!state.currentUser) return Promise.resolve(null);
		//console.log(state.currentUser);
		const user = state.currentUser; //fb().getAuthenticatedUser();
		//console.log(user);
		commit("SET_CURRENT_USER", user);
		return user;
	},
};

// ===
// Private helpers
// ===

function saveState(key, state) {
	window.localStorage.setItem(key, JSON.stringify(state));
}
