<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";

export default {
	name: "app",
	page: {
		// All subcomponent titles will be injected into this template.
		titleTemplate(title) {
			title = typeof title === "function" ? title(this.$store) : title;
			return title ? `${title} | ${appConfig.title}` : appConfig.title;
		},
	},
	mounted() {
		//Stop error resizeObserver
		const debounce = (callback, delay) => {
			let tid;
			return function (...args) {
				const ctx = this;
				clearTimeout(tid);
				tid = setTimeout(() => {
					callback.apply(ctx, args);
				}, delay);
			};
		};

		const _ = window.ResizeObserver;
		window.ResizeObserver = class ResizeObserver extends _ {
			constructor(callback) {
				const debouncedCallback = debounce(callback, 20);
				super(debouncedCallback);
			}
		};
	},
	watch: {
		/**
		 * Clear the alert message on route change
		 */
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
			// clear alert on location change
			this.clearNotification();
		},
	},
	methods: {
		clearNotification: notificationMethods.clear,
	},
};
</script>

<template>
	<div id="app">
		<RouterView />
	</div>
</template>
