import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import router from "@/router";

class FirebaseAuthBackend {
	constructor(firebaseConfig) {
		if (firebaseConfig) {
			// Initialize Firebase
			firebase.initializeApp(firebaseConfig);
			firebase.auth().onAuthStateChanged((user) => {
				if (user) {
					//sessionStorage.setItem("authUser", JSON.stringify(user));
					//console.log("user logged in!");
				} else {
					//sessionStorage.removeItem("authUser");
					//console.log("no user");
					this.handleNoUser();
				}
			});

			firebase.auth().onIdTokenChanged((user) => {
				if (user) {
					user.getIdToken(true).catch((error) => {
						console.error("Token refresh error:", error);
						this.handleAuthError(error);
					});
				}
			});
		}
	}

	handleNoUser() {
		// Redirect to login page
		const path = router?.currentRoute?.path || "";
		if (
			path !== "/login" &&
			path !== "/register" &&
			path !== "/forgot-password" &&
			path !== "/404" &&
			path !== "/logout"
		) {
			router.push("/logout");
		}
	}

	handleAuthError(error) {
		console.error("Authentication error:", error);
		const path = router?.currentRoute?.path || "";
		if (path !== "/login" && path !== "/" && path !== "/logout") {
			router.push("/logout");
		}
		// firebase
		// 	.auth()
		// 	.signOut()
		// 	.then(() => {
		// 		const path = router?.currentRoute?.path || "";
		// 		if (path !== "/login") {
		// 			router.push("/login");
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.error("Error signing out:", error);
		// 	});
	}

	/**
	 * Registers the user with given details
	 */
	registerUser = (email, password) => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then(
					(user) => {
						const auth = firebase.auth();
						// eslint-disable-next-line no-redeclare
						var user = auth.currentUser;
						resolve(user);
					},
					(error) => {
						reject(this._handleError(error));
					}
				);
		});
	};

	getClaims = () => {
		return new Promise((resolve) => {
			const auth = firebase.auth();
			var user = auth.currentUser;

			user.getIdTokenResult(true).then(
				(idTokenResult) => {
					// eslint-disable-next-line no-console
					var returnClaims = null;
					if (idTokenResult) {
						if (idTokenResult.claims) {
							returnClaims = idTokenResult.claims;
						}
					}
					if (returnClaims) {
						resolve(returnClaims);
					} else {
						resolve();
					}
					// eslint-disable-next-line no-unused-vars
				},
				(error) => {
					console.error(error);
					//reject(this._handleError(error));
					resolve(); //no roles
				}
			);
		});
	};

	getRoles = () => {
		return new Promise((resolve) => {
			const auth = firebase.auth();
			var user = auth.currentUser;

			user.getIdTokenResult(true).then(
				(idTokenResult) => {
					var returnRoles = null;
					if (idTokenResult) {
						if (idTokenResult.claims) {
							returnRoles = idTokenResult.claims.roles;
						}
					}
					if (returnRoles) {
						resolve(returnRoles);
					} else {
						resolve();
					}
				},
				(error) => {
					console.error(error);
					//reject(this._handleError(error));
					resolve(); //no roles
				}
			);
		});
	};

	/**
	 * Login user with given details
	 */
	loginUser = (email, password) => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.signInWithEmailAndPassword(email, password)
				.then(
					(user) => {
						const auth = firebase.auth();
						// eslint-disable-next-line no-redeclare
						var user = auth.currentUser;
						resolve(user);
					},
					(error) => {
						reject(this._handleError(error));
					}
				);
		});
	};

	/**
	 * Login user with passwordless link
	 */
	loginUserLink = (email, link) => {
		return new Promise((resolve, reject) => {
			if (firebase.auth().isSignInWithEmailLink(link)) {
				firebase
					.auth()
					.signInWithEmailLink(email, link)
					.then(
						(user) => {
							const auth = firebase.auth();
							// eslint-disable-next-line no-redeclare
							var user = auth.currentUser;
							resolve(user);
						},
						(error) => {
							reject(this._handleError(error));
						}
					);
			} else {
				resolve(null);
			}
		});
	};

	/**
	 * Login user with passwordless link
	 */
	checkEmailLink = (link) => {
		return firebase.auth().isSignInWithEmailLink(link);
	};

	/**
	 * forget Password user with given details
	 */
	forgetPassword = (email) => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.sendPasswordResetEmail(email, {
					url:
						window.location.protocol + "//" + window.location.host + "/login",
				})
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					reject(this._handleError(error));
				});
		});
	};

	/**
	 * Logout the user
	 */
	logout = () => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.signOut()
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					reject(this._handleError(error));
				});
		});
	};

	setLoggeedInUser = (user) => {
		sessionStorage.setItem("authUser", JSON.stringify(user));
	};

	/**
	 * Returns the authenticated user
	 */
	getAuthenticatedUser = () => {
		const auth = firebase.auth();
		var user = auth.currentUser;
		if (!user) return null;
		return user;
	};

	/**
	 * Handle the error
	 * @param {*} error
	 */
	_handleError(error) {
		var errorMessage = error.message;
		return errorMessage;
	}
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
	if (!_fireBaseBackend) {
		_fireBaseBackend = new FirebaseAuthBackend(config);
	}
	return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const fb = () => {
	return _fireBaseBackend;
};

const db = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	const db = firebase.firestore();
	return db;
};

const fs = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	return firebase.firestore.FieldValue.serverTimestamp();
};

const arrayUnion = (value) => {
	if (!_fireBaseBackend) {
		return null;
	}
	return firebase.firestore.FieldValue.arrayUnion(value);
};

const timestamp = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	return firebase.firestore.Timestamp;
};

const auth = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	const auth = firebase.auth();
	return auth;
};

const functions = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	const functionsObj = firebase.functions();
	return functionsObj;
};

const storage = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	const storage = firebase.storage();
	return storage;
};

const currentUser = () => {
	if (!_fireBaseBackend) {
		return null;
	}
	const auth = firebase.auth();
	var user = auth.currentUser;
	return user;
};

export {
	initFirebaseBackend,
	fb,
	db,
	fs,
	currentUser,
	functions,
	storage,
	timestamp,
	auth,
	arrayUnion,
};
